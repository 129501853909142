<template>
    <!-- <keep-alive> -->
    <div id="enterSearch" class="list_content">
        <!-- 查询条件 -->
        <el-form :inline="true" :model="formInline" class="demo-form-inline">

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.name" placeholder="姓名"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.telphone" placeholder="电话"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.province_name" placeholder="省份"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.city_name" placeholder="城市"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-input style="width: 100px;" v-model="formInline.ks_type" placeholder="科室"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-select v-model="formInline.form_status" placeholder="标记状态" style="width: 120px;">
                    <el-option label="全部" value=0></el-option>
                    <el-option label="未操作" value=1></el-option>
                    <el-option label="未接听" value=2></el-option>
                    <el-option label="已致电" value=3></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="">
                <div class="block">
                    <el-date-picker v-model="formInline.create_time" type="datetimerange" align="right"
                        start-placeholder="创建开始日期" end-placeholder="创建结束日期" :default-time="['00:00:01', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss" style="width: 360px;">
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="cx_sunbmit">查询</el-button>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="duSunbmit">导出</el-button>
            </el-form-item>
        </el-form>

        <el-form v-if="updateForm41SHow" :inline="true" :model="updateForm41">
            <el-form-item label="" style="border:1px solid red; ">
                <el-input style="width: 100px;" v-model="updateForm41.ks_type" placeholder="科室-必填"></el-input>
            </el-form-item>

            <el-form-item label="" style="border:1px solid red; ">
                <el-select v-model="updateForm41.app_name" placeholder="平台-必填" style="width: 120px;">
                    <el-option label="字节-抖音" value="字节-抖音"></el-option>
                    <el-option label="字节-今日头条" value="字节-今日头条"></el-option>
                    <el-option label="微信" value="微信"></el-option>
                    <el-option label="快手" value="快手"></el-option>
                    <el-option label="百度" value="百度"></el-option>
                    <el-option label="小红书" value="小红书"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="" style="border:1px solid red; ">
                <el-input style="width: 100px;" v-model="updateForm41.province_name" placeholder="省份-必填"></el-input>
            </el-form-item>

            <el-form-item label="" style="border:1px solid red; ">
                <el-input style="width: 100px;" v-model="updateForm41.city_name" placeholder="城市-必填"></el-input>
            </el-form-item>

            <el-form-item label="" style="border:1px solid red; ">
                <el-input style="width: 100px;" v-model="updateForm41.add_time" placeholder="增加天数-必填"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button style="background:red; border:none;" type="primary"
                    @click="updateFOrm41Func()">开始更新</el-button>
            </el-form-item>
        </el-form>

        <div>

            <!-- 列表内容 -->
            <el-table :data="tableData" :cell-style="{ padding: '2px 0' }" height="634" border stripe>
                <el-table-column label="标记" width="140" align="center">
                    <template slot-scope="scope">
                        <el-button @click="toFormInfo(scope.row)" type="primary" size="small">{{ scope.row.form_status
}}</el-button>
                    </template>
                </el-table-column>

                <el-table-column prop="name" label="姓名" align="center" width="100"></el-table-column>
                <el-table-column prop="telphone" label="电话" align="center" width="150"></el-table-column>
                <el-table-column prop="province_name" align="center" width="180" show-overflow-tooltip label="所在地">
                    <template slot-scope="scope">
                        <el-button v-if='scope.row.province_name != ""' type="text" size="small">{{
        scope.row.province_name + '+' + scope.row.city_name
}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="app_name" align="center" width="100" show-overflow-tooltip
                    label="来源"></el-table-column>
                <el-table-column prop="ks_type" align="center" width="120" show-overflow-tooltip
                    label="科室"></el-table-column>
                <el-table-column prop="remark_dict" align="center" width="200" show-overflow-tooltip
                    label="情况"></el-table-column>
                <!-- <el-table-column v-if="fpztShow" prop="allot_type" align="center" width="80" show-overflow-tooltip label="分配状态"></el-table-column> -->
                <el-table-column prop="remark" align="center" width="300" show-overflow-tooltip
                    label="备注"></el-table-column>
                <el-table-column prop="create_time" align="center" width="180" show-overflow-tooltip
                    label="创建时间"></el-table-column>

                <!-- <el-table-column v-if="fpshShow" prop="allot_date" align="center" width="180" show-overflow-tooltip label="分配时间"></el-table-column> -->
                <el-table-column v-if="sdfpShow" label="操作" width="140" align="center">
                    <template slot-scope="scope">
                        <el-button @click="toSDFP(scope.row)" type="primary" size="small">手动分配</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="current_page" :page-sizes="[15, 20, 25]" :page-size="page_size"
                layout="total, sizes, prev, pager, next, jumper" :total="data_num" :sizes='page_num'>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import LeftMenu from '@/components/left.vue'
import Top from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'FormListView',
    components: {
        LeftMenu,
        Top,
        Footer
    },
    data() {
        return {
            cache_user: {},
            sec_user: {},
            formInline: {},
            tableData: [],
            current_page: 1, //初始页
            page_size: 15, // 每页的数据
            data_num: 0, // 总条数
            page_num: 0, // 总页数
            userList: [],
            fpztShow: false,
            fpshShow: false,
            sdfpShow: false,
            updateForm41: {},
            updateForm41SHow: false,
        }
    },
    methods: {
        updateFOrm41Func() {
            let that = this;
            that.updateForm41.add_time = Number(that.updateForm41.add_time);
            console.log("that.updateForm41: " + that.updateForm41)
            this.myAxios.post(that.myBaseurl + '/form/update/id41', that.updateForm41)
                .then(function (res) {
                    alert(res.data.Msg);
                })
        },
        duSunbmit() {
            this.$confirm("确定导出吗?").then(() => {
                window.location.href = this.myBaseurl + "/form/excel/download?user_id=" + this.sec_user.user_id + "&user_name=" + this.sec_user.user_name
            })
        },
        cx_sunbmit() {
            this.current_page = 1
            this.onSubmit()
        },
        onSubmit() {
            console.log('开始查询!');
            const that = this
            if (that.sec_user.user_level == 3) {
                that.formInline.user_id = that.sec_user.user_id
            }
            console.log("------------------- : ", that.sec_user);
            if (that.sec_user.user_id == 41) {
                that.updateForm41SHow = true;
            }

            if (that.formInline.form_status) {
                that.formInline.form_status = Number(that.formInline.form_status)
            }
            that.formInline.current_page = Number(that.current_page)
            if (that.formInline.create_time && that.formInline.create_time.length >= 2) {
                that.formInline.create_time_start = that.formInline.create_time[0]
                that.formInline.create_time_end = that.formInline.create_time[1]
            }

            this.myAxios.post(that.myBaseurl + '/form/list', that.formInline)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log("submit--res : ", res)
                        that.tableData = res.data.Data.List
                        that.data_num = res.data.Data.TotalCount
                        that.page_num = res.data.Data.TotalPage
                        // 添加权限显示
                        if (that.cache_user.user_level == 1 || that.cache_user.user_level == 2) {
                            that.fpztShow = true
                            that.fpshShow = true
                            that.sdfpShow = true
                        }

                        for (let item of that.tableData) {
                            switch (item.allot_type) {
                                case 1:
                                    item.allot_type = "未分配"
                                    break;
                                case 2:
                                    item.allot_type = "已分配"
                                    break;
                            }

                            switch (item.form_status) {
                                case 1:
                                    item.form_status = "未标记"
                                    break;
                                case 2:
                                    item.form_status = "未接听"
                                    break;
                                case 3:
                                    item.form_status = "已致电"
                                    break;
                            }
                        }
                    } else {
                        alert("查询失败 : " + res.data.Msg)
                    }
                })

            // sessionStorage.setItem('cache_user_page', JSON.stringify(this.formInline))
        },
        // 初始页current_page、初始每页数据数page_size和数据data
        handleSizeChange: function (size) {
            this.page_size = size;
            console.log(this.page_size) //每页下拉显示数据
            this.onSubmit()
        },
        handleCurrentChange: function (current_page) {
            this.current_page = current_page;
            console.log(this.current_page) //点击第几页
            this.onSubmit()
        },

        toFormInfo: function (u) {
            this.$router.push({
                path: '/form/info',
                query: {
                    form_id: u.id
                }
            })
        },
        toSDFP(u) {
            this.$router.push({
                path: '/form/sdfp',
                query: {
                    form_id: u.id
                }
            })
        }
    },

    created() {
        console.log('开始查询!')
        // 从缓存获取user
        this.cache_user = JSON.parse(sessionStorage.getItem('user'))
        // 从缓存获取 列表user
        this.sec_user = JSON.parse(sessionStorage.getItem('sec_user'))
        this.onSubmit()
    },

    mounted() {
        // 监听回车键查询
        let that = this;
        let enterSearchEle = document.getElementById("enterSearch")
        console.log(enterSearchEle);
        enterSearchEle.addEventListener("keydown", function (e) {
            console.log(e);
            if (e.key == "Enter") {
                that.$xianliu(that.onSubmit, 500)()
            }
        })
    }
}

</script>

<style lang="less" scoped="scoped">
body {
    margin: 0px;
    padding: 0px;
}

.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    text-align: center;
    width: 100%;
}

.el-table-column {
    line-height: 20px;
}

.el-table__row .hover-row {
    background-color: red !important;
}

a {
    text-decoration: none;
}
</style>
